<template>
    <div class="markup-content" v-if="node.content" :class="{'_extend': getIsExtend}">

      <div class="markup-content__inner">
        <div class="markup-content__than-block">
          <span class="markup-content__than-text">
              То
          </span>
        </div>

        <div class="markup-content__outer">

          <div class="markup-content__main">
            <v-select
                class="markup-content__select markup-content__select_strategy"
                v-model="node.content.strategy"
                label="name"
                :reduce="(option) => option.value"
                :options="strategies"
                :clearable="false"
                :searchable="false"
            ></v-select>

              <v-select
                  v-if="isCustom || isChange"
                  class="markup-content__select markup-content__select_strategy"
                  v-model="node.content.source"
                  label="name"
                  :reduce="(option) => option.value"
                  :options="sources"
                  :clearable="false"
                  :searchable="false"
              ></v-select>

            <div class="markup-content__main-title" v-if="isChange">
              на
            </div>
            <div class="markup-content__main-title" v-else>
              <span v-if="isCustom">по формуле</span>
            </div>


            <input v-if="isCustom"
                   v-model="node.content.custom"
                   class="markup-content__input markup-content__input_custom"
                   :class="{'_error': !formulaIsValid}"
                   type="text"
                   autocomplete="off"
                   @input="mathValidate"
            >

            <input v-else
                   v-model="node.content.value"
                   class="markup-content__input"
                   type="text"
                   autocomplete="off"
            >

            <v-select
                class="markup-content__select markup-content__select_base"
                v-if="isChange"
                v-model="node.content.base"
                label="name"
                :reduce="(option) => option.value"
                :options="[{name: '%', value: 'percent'}, {name: 'руб', value: 'money'}]"
                :clearable="false"
                :searchable="false"
            ></v-select>

          </div>

          <div class="markup-content__main">
            <input type="checkbox" class="markup-content__input markup-content__input_checkbox" v-model="node.content.gteRRC">
            <label>
              Не ниже РРЦ
            </label>

            <div class="markup-content__main-field" v-if="getIsExtend">
              <input type="checkbox" class="markup-content__input markup-content__input_checkbox" v-model="node.content.isShares">
              <label>
                Оплата в доли
              </label>
            </div>

            <div class="markup-content__main-field" v-if="getIsExtend">
              <input type="checkbox" class="markup-content__input markup-content__input_checkbox" v-model="node.content.isPreOrder">
              <label>
                Предзаказ
              </label>
            </div>
          </div>

          <div class="markup-content__extend" v-if="getIsExtend">
            <div class="markup-content__field">
              <label>
                Размер комплекта
              </label>
              <input class="markup-content__input" type="text" v-model="node.content.kit" autocomplete="off">
            </div>

            <div class="markup-content__field">
              <label>
                Мин. процент маржи
              </label>
              <input class="markup-content__input" type="text" v-model="node.content.marginality" autocomplete="off">
            </div>

            <div class="markup-content__field">
              <label>
                Процент от цены конкурента
              </label>
              <input class="markup-content__input" type="text" v-model="node.content.competitor_percent" autocomplete="off">
            </div>

            <div class="markup-content__field">
              <label>
                Процент скидки
              </label>
              <input class="markup-content__input" type="text" v-model="node.content.discount_percent" autocomplete="off">
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import Vue from 'vue'
import vSelect from 'vue-select'
import {mapActions, mapGetters} from "vuex";
import tree from "../../../mixins/tree";

Vue.component('v-select', vSelect)

export default {
  name: "MarkupTree",
  props: {
    node: Object,
    treeId: String
  },
  data() {
    return {
      mathDebounceOrder: 0,
      formulaIsValid: true,
      strategies: [
        {name: 'Увеличить', value: 'increase'},
        {name: 'Уменьшить', value: 'decrease'},
        {name: 'Установить', value: 'set'},
        {name: 'Рассчитать', value: 'custom'}
      ],
      sources: [
        {name: 'Закупочную цену', value: 'incoming'},
        {name: 'Оптовую цену', value: 'retail'},
        {name: 'Цену сайта', value: 'site'},
      ]
    }
  },

  computed: {
    isCustom() {
      return this.node.content.strategy === 'custom';
    },
    isChange() {
      return ['increase', 'decrease'].includes(this.node.content.strategy)
    },
    ...mapGetters('tree', {
      'isExtend': 'isExtend'
    }),
    getIsExtend() {
      return this.isExtend(this.treeId);
    }
  },
  methods: {
    ...mapActions('tree', {
      'validateFormula': 'validateFormula'
    }),
    getDefaultContent() {
      return {
        value: 10,
        strategy: 'increase',
        base: 'percent',
        source: 'incoming',
        custom: ''
      }
    },
    async debounceMathValidate() {
      this.mathDebounceOrder--;
      if (this.mathDebounceOrder <= 0) {
        this.mathDebounceOrder = 0;
        this.formulaIsValid = await this.validateFormula({formula: this.node.content.custom});
      }
    },
    async mathValidate(e) {
      this.mathDebounceOrder++;
      setTimeout(this.debounceMathValidate, 1500);
    }
  },
  mounted() {
    if (!this.node.content) {
      this.$set(this.node, 'content', this.getDefaultContent());
    }
  }
}
</script>

<style lang="scss">

</style>