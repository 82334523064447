import FilterCollectionService from "../../services/filterCollection.service";

const CatalogFilter = {
  namespaced: true,
  state: {
    loading: false,
    loaded: false,
    totalPreloader: false,
    productPreloader: false,
    settings: {
      hasAuto: false,
      productType: '',
      filterMode: '',
      categoryId: null,
      hideStorages: false,
    },
    mode: 'param', // param|auto
    totalProducts: null,
    currentOrder: null,
    hidePrice: false,
    columns: [
      { name: 'Производитель', key: 'producer' },
      {
        name: 'Наименование',
        key: 'name',
        sort: true,
      },
      {
        name: 'Сайт',
        key: 'internal-price',
        forAdmin: true,
        onlyInternal: true,
        sort: true,
      },
      {
        name: 'РРЦ',
        key: 'rrc',
        sort: true,
        name_alt: 'Цена',
      },
      { name: 'Склады', key: 'storage' },
      { name: 'В наличии', key: 'quantity', sort: true },
      { name: 'Цена', key: 'price', sort: true },
      { name: 'Цена продажи', key: 'sell-price', sort: true },
      {
        name: 'Вход',
        key: 'incoming',
        sort: true,
        forAdmin: true,
      },
      { name: 'Количество', key: 'order' },
    ],
  },
  getters: {
    isParamFilter: (state) => state.mode === 'param',
    isAutoFilter: (state) => state.mode === 'auto',
    hasAutoTab: (state) => state.settings.hasAuto,
    productType: (state) => state.settings.productType,
    categoryId: (state) => state.settings.categoryId,
    hideStorages: (state) => state.settings.hideStorages,
  },
  actions: {
    async initFilter({ state, commit, dispatch }, settings) {
      if (!settings.productType) {
        console.log('Need productType in filter settings');
        return;
      }

      if (!state.loading && !state.loaded) {
        commit('setSettings', settings);
        commit('setLoading', true);

        let prevType = false;
        // let prevType = settings.prevFilterType;

        if (prevType) {
          try {
            if (prevType === 'param') {
              await dispatch('filterParam/loadPossibleFields', { filterMode: state.settings.filterMode }, { root: true });
              await dispatch('filterParam/applyPrevFilter', {}, { root: true })
            } else if (prevType === 'auto') {
              await commit('setMode', 'auto');
              await dispatch('autoParam/applyPrevFilter', {}, { root: true })
            }
          } catch (err) {
            console.log(err);
            prevType = null;
          }
        }

        if (!prevType) {
          await dispatch('filterParam/loadPossibleFields', { filterMode: state.settings.filterMode }, { root: true });
          await dispatch('filterPostProcessing');
        }

        commit('setLoading', false);
        commit('setLoaded', true);

        if (prevType && prevType === 'auto') {
          await dispatch('autoParam/fetchProducts', { }, { root: true });
        } else {
          await dispatch('filterParam/fetchProducts', { }, { root: true });
        }
      }
    },

    async filterPostProcessing({state, dispatch, commit, rootState}) {
      // Кусок для нахождения товара или фильтра в поиске по прямой ссылке
      const urlGetParameters = window.location.search.slice(1)
        .split('&')
        .reduce((accumulator, value) => {
          value = value.split('=');
          accumulator[value[0]] = decodeURIComponent(value[1]);
          return accumulator;
        }, {});

      if (urlGetParameters.internal_id) {
        const internalField = rootState.filterParam.fields.find((field) => field.key === 'internal_id');
        if (internalField) {
          commit('filterParam/setCurrentValue', { fieldKey: 'internal_id', value: urlGetParameters.internal_id }, { root: true });
          await dispatch('filterParam/updatePossibleFields', { filterMode: state.settings.filterMode }, { root: true });
        }
      } else if (urlGetParameters.param_filter_save) {
        await dispatch('filterCollection/fetchList', { }, { root: true });
        await dispatch('filterCollection/apply', urlGetParameters.param_filter_save, { root: true });
      } else if (urlGetParameters.auto_filter_save) {
        dispatch('switchMode', 'auto');
        await dispatch('filterCollection/fetchList', { }, { root: true });
        await dispatch('filterCollection/apply', urlGetParameters.auto_filter_save, { root: true });
      }
      // Конец куска
    },

    replaceCatalogContent({}, html) {
      const page = document.createElement('div');
      page.innerHTML = html;
      const newData = page.querySelector('[data-catalog-list]');
      const currentData = document.querySelector('[data-catalog-list]');
      currentData.innerHTML = newData.innerHTML;
    },

    async setOrder({ state, commit, dispatch }, order) {
      commit('setCurrentOrder', order);
      if (state.mode === 'param') {
        await dispatch('filterParam/fetchProducts', {}, { root: true });
      } else if (state.mode === 'auto') {
        await dispatch('autoParam/fetchProducts', {}, { root: true });
      }
    },
    updateHidePrice({ commit }, value) {
      const catalogElement = document.querySelector('[data-catalog-list-block]');
      if (catalogElement) {
        if (value) {
          catalogElement.classList.add('_hide-price');
        } else {
          catalogElement.classList.remove('_hide-price');
        }
      }
      commit('setHidePrice', value);
    },
    async switchMode({ commit, dispatch, state, rootState }, mode) {
      // commit('catalogFilter/setLoading', true, { root: true });

      try {
        await commit('setMode', mode);
        if (state.mode === 'param') {
          await dispatch('filterParam/loadPossibleFields', {}, { root: true });
          await dispatch('filterParam/fetchProducts', {}, { root: true });
        } else if (state.mode === 'auto' && rootState.autoParam.extend) {
          await dispatch('autoParam/updatePossibleFields', {}, { root: true });
          await dispatch('autoParam/fetchProducts', {}, { root: true });
        }
      } catch (e) {
        dispatch('notifications/showErrorNotification', '', { root: true });
      }

      // commit('catalogFilter/setLoading', false, { root: true });
    },
  },
  mutations: {
    setMode(state, mode) {
      state.mode = mode;
    },
    setSettings(state, settings) {
      state.settings = settings;
    },
    setTotalProducts(state, count) {
      state.totalProducts = count;
    },
    setLoading(state, loading) {
      state.loading = loading;
    },
    setLoaded(state, loaded) {
      state.loaded = loaded;
    },
    setCurrentOrder(state, order) {
      state.currentOrder = order;
    },
    setHidePrice(state, hidePrice) {
      state.hidePrice = hidePrice;
    },
    setTotalPreloader(state, totalPreloader) {
      state.totalPreloader = totalPreloader;
    },
    setProductPreloader(state, productPreloader) {
      state.productPreloader = productPreloader;
    },
  },
};

export default CatalogFilter;