var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("form", { staticClass: "cart-manager-form" }, [
    _c("h3", { staticClass: "cart-manager-form__title" }, [
      _vm._v("\n    Автозаказ\n  "),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "cart-manager-form__fields" }, [
      _c(
        "div",
        { staticClass: "cart-manager-form__field" },
        [
          _c("base-field-switch", {
            attrs: {
              label: "Создавать автоматический заказ",
              "input-id": "create_auto_order_switch",
              "current-value": _vm.orderData.createAutoOrder,
            },
            model: {
              value: _vm.orderData.createAutoOrder,
              callback: function ($$v) {
                _vm.$set(_vm.orderData, "createAutoOrder", $$v)
              },
              expression: "orderData.createAutoOrder",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "cart-manager-form__field" },
        [
          _vm.orderData.createAutoOrder
            ? _c("base-field-select", {
                attrs: {
                  "field-id": "order_shipment_select",
                  values: _vm.shipments,
                  "current-value": _vm.orderData.shipment,
                  "no-default": true,
                  label: "Способ доставки",
                },
                model: {
                  value: _vm.orderData.shipment,
                  callback: function ($$v) {
                    _vm.$set(_vm.orderData, "shipment", $$v)
                  },
                  expression: "orderData.shipment",
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "cart-manager-form__field" },
        [
          _vm.orderData.createAutoOrder && _vm.orderData.shipment !== "pickup"
            ? _c("base-field-select", {
                attrs: {
                  "field-id": "order_address_select",
                  values: _vm.internalAddresses,
                  "current-value": _vm.orderData.internalAddress,
                  "no-default": true,
                  label: "Адрес доставки",
                },
                model: {
                  value: _vm.orderData.internalAddress,
                  callback: function ($$v) {
                    _vm.$set(_vm.orderData, "internalAddress", $$v)
                  },
                  expression: "orderData.internalAddress",
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "cart-manager-form__field" },
        [
          _vm.orderData.createAutoOrder
            ? _c("base-field-switch", {
                attrs: {
                  label: "Использовать бонусы (при возможности)",
                  "input-id": "order_use_bonuses_switch",
                  "current-value": _vm.orderData.useBonuses,
                },
                model: {
                  value: _vm.orderData.useBonuses,
                  callback: function ($$v) {
                    _vm.$set(_vm.orderData, "useBonuses", $$v)
                  },
                  expression: "orderData.useBonuses",
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "cart-manager-form__field" },
        [
          _vm.orderData.createAutoOrder && _vm.isForInternalManager
            ? _c("base-field-select", {
                attrs: {
                  "field-id": "four_points_contract_select",
                  values: _vm.fourPointsContracts,
                  "current-value": _vm.orderData.fourPointsContract,
                  label: "Договор для 4 точек",
                  "default-value": { name: "По умолчанию", value: null },
                },
                model: {
                  value: _vm.orderData.fourPointsContract,
                  callback: function ($$v) {
                    _vm.$set(_vm.orderData, "fourPointsContract", $$v)
                  },
                  expression: "orderData.fourPointsContract",
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }